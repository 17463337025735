import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import TelemetryFormBlock from '../reused-components/FormBlock';
import { fetchSiteInfo } from "../../services/apiService";
import ThemesPluginsTable from '../reused-components/ThemesPluginsTable';
import ProductEventsLogTable from '../tables/ProductEventsLogTable';
import TelemetryAccordion from '../reused-components/Accordian';
import { useNavigate } from 'react-router-dom';


export default function SiteDetails() {
    document.title = "SiteDetails | Telemetry";

    const { siteId } = useParams(); // Extract siteId from the URL
    const navigate = useNavigate();

    const [SiteData, setSiteInfo] = useState({
        url: '',
        title: '',
        pluginVersion: '',
        sdkVersion: '',
        phpVersion: '',
        wordpressVersion: '',
        locale: '',
        location: '',
        owner: '',
        user_id: ''
    });

    const handleUserDetailClick = (userId) => {
        navigate(`/users/${userId}`);
    };

    useEffect(() => {
        if (!siteId) {
            console.warn('siteId is undefined!');
            return;
        }

        console.log('Selected Site ID:', siteId); // Log siteId to check if it's correctly set

        const fetchData = async () => {
            try {
                const data = await fetchSiteInfo(siteId);
                console.log('API Response:', data);

                // Check if data and meta_data are defined
                if (!data || !data.meta_data) {
                    console.error('meta_data is missing or data is undefined');
                    return;
                }

                const metaData = data.meta_data.reduce((acc, item) => {
                    acc[item.meta_key] = item.meta_value;
                    return acc;
                }, {});

                setSiteInfo({
                    url: data.site_url || '',
                    title: data.site_title || '',
                    created_at: data.created_at || '',
                    pluginVersion: data.plugin_version || '',
                    sdkVersion: data.sdk_version || '',
                    phpVersion: metaData.php_version || '',
                    wordpressVersion: metaData.wp_version || '',
                    locale: metaData.wp_locale || '',
                    location: metaData.wp_location || '',
                    owner: data.owner || '',
                    user_id: data.user_id || ''


                });
            } catch (error) {
                console.error('Error fetching product settings:', error);
            }
        };

        fetchData();
    }, [siteId]);

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry" /></a>
                </div>
                <div className="telemetry-top">
                    <h1>Sites</h1>
                    <div className="telemetry-top-right">
                        <AddProducts />
                        <Profile image={profileImage} />
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1} />
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-settings-tab">
                        <div className="telemetry-setting-tab-content">
                            <div className="telemetry-setting-box">
                                <h2>Site Information</h2>
                                <div className="telemetry-box-ov">
                                    <div className="telemetry-setting-block-2">
                                        <TelemetryFormBlock
                                            label="URL"
                                            inputType="url"
                                            value={SiteData.url}
                                            name="url"
                                            id="url"
                                        />
                                        <TelemetryFormBlock
                                            label="Title"
                                            inputType="text"
                                            value={SiteData.title}
                                            name="title"
                                            id="title"
                                        />
                                        <TelemetryFormBlock
                                            label="Installed at"
                                            inputType="text"
                                            value={SiteData.created_at}
                                            name="installedat"
                                            id="installedat"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="Country"
                                            inputType="text"
                                            value={SiteData.location}
                                            name="location"
                                            id="location"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="Language"
                                            inputType="text"
                                            value={SiteData.locale}
                                            name="locale"
                                            id="locale"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="Plugin Version"
                                            inputType="text"
                                            value={SiteData.pluginVersion}
                                            name="pluginVersion"
                                            id="pluginVersion"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="WordPress Version"
                                            inputType="text"
                                            value={SiteData.wordpressVersion}
                                            name="wordpressVersion"
                                            id="wordpressVersion"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="SDK Version"
                                            inputType="text"
                                            value={SiteData.sdkVersion}
                                            name="sdkVersion"
                                            id="sdkVersion"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                        <TelemetryFormBlock
                                            label="PHP Version"
                                            inputType="text"
                                            value={SiteData.phpVersion}
                                            name="phpVersion"
                                            id="phpVersion"
                                            width="telemetry-form-block-1-4"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="telemetry-setting-box owner">
                                <h3>Admin & Owner</h3>
                                <div className="telemetry-box-ov">
                                    <span onClick={() => handleUserDetailClick(SiteData.user_id)} className="link-icon">{SiteData.owner}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <TelemetryAccordion title="Themes and Plugins">
                        <ThemesPluginsTable siteId={siteId} />
                    </TelemetryAccordion>
                    <TelemetryAccordion title="Events">
                        <ProductEventsLogTable paginationPerPage={10} type={'all'} siteId={siteId} />
                    </TelemetryAccordion>
                </section>
            </main>
        </div>
    );
}
