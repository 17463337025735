import React, { useState } from 'react';

function FilterDropdown({ onFilterChange }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState('All');

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (value) => {
        setSelectedValue(value);
        setIsOpen(false);
        onFilterChange(value);
    };

    return (
        <div className="telemetry-select-dd no-seprator telemetry-change-length">
            <span onClick={toggleDropdown}>{selectedValue}</span>

            {isOpen && (
                <ul className="telemetry-dd">
                    <li onClick={() => handleSelect('all')}>All</li>
                    <li onClick={() => handleSelect('activate')}>Activate</li>
                    <li onClick={() => handleSelect('deactivate')}>Deactivate</li>
                    <li onClick={() => handleSelect('uninstall')}>Uninstall</li>
                </ul>
            )}
        </div>
    );
}

export default FilterDropdown;
