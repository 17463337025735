export const fetchDashboardChartData = async (product_id, meta_key, group_by_period, start_date, end_date, compare_start_date, compare_end_date, showGrowth) => {
    try {


        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/dashboard/${product_id}/${meta_key}?group_by_period=${group_by_period}&start_date=${start_date}&end_date=${end_date}`;

        // Add the comparison dates if it is provided
        if (compare_start_date && compare_end_date && showGrowth) {
            url += `&compare_start_date=${compare_start_date}&compare_end_date=${compare_end_date}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const responseData = await response.json();
        return responseData.data;
    } catch (error) {
        throw error;
    }
};

export const fetchDashboardStats = async (product_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/dashboard/${product_id}/stats`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const responseData = await response.json();
        return responseData.data;
    } catch (error) {
        throw error;
    }
};

export const addNewStore = async (storeData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/stores/add-store`, {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: storeData,
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error adding new store:', error);
        throw error;
    }
};

export const fetchStores = async (storeId) => {
    let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/stores`;

    if (storeId) {
        url += `/${storeId}`;
    }
    try {
        const response = await fetch(url, {

            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error fetching stores:', error);
        throw error;
    }
};

export const updateStoreData = async (storeId, storeData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/stores/${storeId}/settings`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(storeData)
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error updating store data:', error);
        throw error;
    }
};

export const addNewProduct = async (productData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/product`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify(productData),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error adding new product:', error);
        throw error;
    }
};

export const fetchStoreUsers = async (storeId, page = 1, perPage = 10, searchTerm = '') => {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/stores/${storeId}/users?page=${page}&per_page=${perPage}`;

        // Add the search term if it is provided
        if (searchTerm) {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};


export const fetchUsers = async (productId, page = 1, perPage = 10, searchTerm = '') => {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/${productId}/customers?page=${page}&per_page=${perPage}`;

        // Add the search term if it is provided
        if (searchTerm) {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const UserData = async (user_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/user/details/${user_id}`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const updateUserData = async (userData, userId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/user/profile/${userId}`, {
            method: 'PUT',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Network response was not ok: ${errorData.message}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating user data:', error);
        throw error;
    }
};

export const fetchUser = async (user_id) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/${user_id}`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchSites = async (productId, page, perPage, userId = null, searchTerm = '') => {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/${productId}/sites?page=${page}&per_page=${perPage}`;

        if (userId) {
            url += `&customerId=${userId}`;
        }

        if (searchTerm) {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error); // Log error details
        throw error;
    }
};

export const fetchStoreSites = async (storeId, page = 1, perPage = 10, userId = null, searchTerm = '') => {
    try {
        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/stores/${storeId}/sites?page=${page}&per_page=${perPage}`;


        if (userId) {
            url += `&customerId=${userId}`;
        }

        if (searchTerm) {
            url += `&search=${encodeURIComponent(searchTerm)}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        console.error('Fetch error:', error); // Log error details
        throw error;
    }
};

export const fetchStoreEventLogs = async (storeId, page, type, userId = null, siteId = null) => {
    try {


        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/stores/${storeId}`;


        url += `/events-log?page=${page}`;

        if (userId) {
            url += `&customerId=${userId}`;
        }

        if (siteId) {
            url += `&siteId=${siteId}`;
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({type})
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};


export const fetchEventLogs = async (productId, page, type, userId = null, siteId = null) => {
    try {


        let url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/products/${productId}`;


        url += `/product-events-log?page=${page}`;

        if (userId) {
            url += `&customerId=${userId}`;
        }

        if (siteId) {
            url += `&siteId=${siteId}`;
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({type})
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchProductSettings = async (productId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/product-settings/${productId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const fetchSiteInfo = async (siteId) => {
    console.log('siteId being passed:', siteId);

    if (!siteId) {
        console.error('siteId is not defined!');
        return;
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/sitemeta/${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        console.error('Error fetching site info:', error); // Improved error logging
        throw error;
    }
}


export const fetchThemesPlugins = async (siteId) => {
    try {
        if (!siteId) {
            throw new Error('SiteID is not defined!');
        }
        // Append siteID as a query parameter in the URL
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/siteplugins/${siteId}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        if (!response.ok) {
            // Throwing a detailed error including status code and status text
            throw new Error(`Network response was not ok: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching themes and plugins:', error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const fetchSiteEventLogs = async (siteID) => {
    try {
        if (!siteID) {
            throw new Error('SiteID is not defined!');
        }

        console.log('SiteID being passed:', siteID);

        // Append siteID as a query parameter in the URL
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/site-events/${siteID}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });

        if (!response.ok) {
            // Throwing a detailed error including status code and status text
            throw new Error(`Network response was not ok: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Fetched data:', data);
        return data;
    } catch (error) {
        console.error('Error fetching themes and plugins:', error);
        throw error; // Re-throw the error to be handled by the calling function
    }
};

export const ScriptInfo = async (productId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/integration-script/${productId}`, {
            method: 'GET',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.text();
    } catch (error) {
        throw error;
    }
}

export const saveProductSettings = async (productData, productId) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/update-product-settings/${productId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify({
                slug: productData.slug,
                title: productData.title,
                website: productData.website,
                short_description: productData.short_description
            })
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}


export const getUserProducts = async (productData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user-products`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}


export const getUserStoresWithProducts = async (productData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/stores`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}