import React, { useState } from "react";
import ToggleDropdown from '../reused-components/SidebarTabs';
import AddProducts from '../reused-components/AddProducts';
import Profile from '../reused-components/Profile';
import smlogo from '../../img/sm-logo.svg';
import profileImage from '../../img/user-profile.png';
import DataTableFilter from "../reused-components/DataTableFilter";
import SitesTable from '../tables/SitesTable';
import SearchFilter from "../reused-components/SearchFilter";

export default function Sites() {
    document.title = "Sites";

    const [perPage, setPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const handlePerPageClick = (perPage) => {
        setPerPage(perPage);
    };

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
    };

    return (
        <div className="telemetry-page-wrapper">
            <header className="telemetry-header">
                <div className="telemetry-logo-section">
                    <a href="#"><img src={smlogo} alt="Telemetry" /></a>
                </div>
                <div className="telemetry-top">
                    <h1>Sites</h1>
                    <div className="telemetry-top-right">
                        <SearchFilter onSearch={handleSearch} />
                        <AddProducts />
                        <Profile image={profileImage} />
                    </div>
                </div>
            </header>
            <main className="telemetry-main">
                <aside className="telemetry-sidebar">
                    <ToggleDropdown tabactive={1} tabactive2={1} />
                </aside>
                <section className="telemetry-content">
                    <div className="telemetry-filter">
                        <DataTableFilter handlePerPageClick={handlePerPageClick} />
                    </div>
                    <div className="telemetry-data-table-wrapper">
                        <div className="telemetry-data-table-container">
                            <SitesTable 
                                paginationPerPage={perPage} 
                                searchTerm={searchTerm} 
                            />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}
