import React, {useState, useEffect} from 'react';
import DataTable from 'react-data-table-component';
import {fetchThemesPlugins} from '../../services/apiService'; // Replace with your actual API service

const columns = [
    {
        name: 'ID',
        selector: row => row.id,
        sortable: true,
        className: "telemetry-form-block-1-4"
    },
    {
        name: 'Title',
        selector: row => row.name,
        sortable: true,
        className: "telemetry-form-block-full"

    },
    {
        name: 'Slug',
        selector: row => row.slug,
        sortable: true,
        className: "telemetry-form-block-full"

    },
    {
        name: 'State',
        selector: row => row.state,
        sortable: true,
        cell: row => (
            <span className={`badge ${row.state === 'active' ? 'green-badge' : 'orange-badge'}`}>
                {row.state}
            </span>
        ),
    },
    {
        name: 'Last Updated',
        selector: row => row.updated_at,
        sortable: true,
        cell: row => new Date(row.updated_at).toLocaleDateString(),
    },
];

function ThemesPluginsTable({siteId}) {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseData = await fetchThemesPlugins(siteId);
                const dataWithIds = responseData.map((item, index) => ({
                    id: index + 1,
                    ...item
                }));

                setData(dataWithIds || []);
            } catch (error) {
                console.error('Error fetching themes and plugins data:', error);
            }
        };
        fetchData();
    }, [siteId]);

    return (
        <DataTable
            columns={columns}
            data={data}
            responsive
            className="custom-data-table"
            noDataComponent="No records to display"
        />
    );
}

export default ThemesPluginsTable;
